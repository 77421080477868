import goals from '@/mixins/goals';

export default {
  mixins: [goals],
  methods: {
    getValueText({ operator, value }) {
      if (operator !== 'interval') return value;
      return `${value[0]} ${this.$t('and')} ${value[1]}`;
    },
    handleCampaignGoals({ type, value }) {
      const typeText = `conversionGoals.eventTypes.${type}`;
      const selectedCampaign = this.campaignList.find(
        (campaign) => `${campaign.id}` === `${value}`,
      );

      const userId = this.$route.params.userId;
      return `${this.$t(typeText)}: <a href="/${userId}/campaign/${selectedCampaign?.id}">${
        selectedCampaign?.name
      }</a>`;
    },
    handleDefaultGoals({ name }) {
      if (name === 'purchase') return this.$t('conversionGoals.defaultGoals.purchase');
      if (name === 'addToCart') return this.$t('conversionGoals.defaultGoals.addToCart');
    },
    handleFieldEvents({ field, operator, value }) {
      const fieldText = `conversionGoals.rulesExplanation.${field}`;
      return `${this.$t(fieldText)} ${this.$t(
        `conversionGoals.rulesExplanation.${operator}`,
      )} ${this.getValueText({ operator, value })}`;
    },
    handleCustomGoals({ type, field, operator, value }) {
      const event = this.getCustomEventName(type);
      if (!field) {
        return `${this.$t(`conversionGoals.rulesExplanation.customExists`, { event })}`;
      }
      return `${this.$t(`conversionGoals.rulesExplanation.custom`, { event, field })} ${this.$t(
        `conversionGoals.rulesExplanation.${operator}`,
      )} ${this.getValueText({ operator, value })}`;
    },
    getRuleText({ name, rules, isDefault }) {
      const { operator: ruleOperator } = rules;
      const ruleText = rules?.expressions?.map((rule) => {
        const { type, field, operator, value } = rule;

        if (isDefault) {
          return this.handleDefaultGoals({ name });
        }

        if (type.startsWith(this.customEventPrefix)) {
          return this.handleCustomGoals(rule);
        }

        if (this.campaignEvents.includes(type)) {
          return this.handleCampaignGoals({ type, value });
        }

        if (this.fieldEvents.includes(type)) {
          return this.handleFieldEvents({ field, operator, value });
        }

        return `${this.$t(`conversionGoals.eventTypes.${type}`)} ${this.$t(
          `conversionGoals.rulesExplanation.${field}`,
        )} ${this.$t(`conversionGoals.rulesExplanation.${operator}`)} "${value}"`;
      });

      if (ruleOperator) return ruleText?.join(`</br>${this.$t(ruleOperator)}</br>`);
      return ruleText?.join(`</br>`);
    },
  },
};
