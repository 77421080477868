<template lang="pug">
.goals-description
  .goals-description-left-shape
    img(:src="require('@/assets/admin/img/goals/left_shape.svg')" style="height: 100%")
  .goals-description-right-shape
    img(:src="require('@/assets/admin/img/goals/right_shape.svg')")
  .row.description-container
    .d-flex.align-items-center.text-section
      div(:class="{ 'extra-padding': !showNew }")
        om-heading.mb-3(h3 v-html="$t('conversionGoals.dcFlow.descriptionModule.title')")
        .goals-desc {{ $t('conversionGoals.dcFlow.descriptionModule.description') }}
        .goals-btns.mt-5.d-flex.flex-column.align-items-start
          om-button(ghost primary small @click="readMore()" icon="book-alt") {{ $t('conversionGoals.dcFlow.descriptionModule.readMore') }}
          om-button(ghost primary small @click="watchVideo()" icon="play-circle") {{ $t('conversionGoals.dcFlow.descriptionModule.watchVideo') }}
          om-button#goals-give-feedback(ghost primary small icon="comment-dots") {{ $t('conversionGoals.dcFlow.descriptionModule.giveFeedback') }}
    .d-flex.flex-column.align-items-end.justify-content-center.example-section
      .title.d-flex.font-weight-bold.font-size-0--75 {{ $t('conversion') }}
      .example-goals.d-flex.flex-column.mt-2
        template(v-for="goal in exampleGoals")
          om-select-card(:title="goal.title" :icon="goal.icon" :arrow="false" small backgroundOff)
            .conversion-number.font-size-1--125(slot="rightContent") {{ goal.conversion }}
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      showNew: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        exampleGoals: [
          {
            title: this.$t(`conversionGoals.dcFlow.descriptionModule.blogPageVisit`),
            icon: 'retarget',
            conversion: 856,
          },
          {
            title: this.$t(`conversionGoals.dcFlow.descriptionModule.addToCart`),
            icon: 'stop-cart-abandonment',
            conversion: 275,
          },
          {
            title: this.$t(`conversionGoals.dcFlow.descriptionModule.purchase`),
            icon: 'purchase',
            conversion: 136,
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['getLocale']),
    },
    methods: {
      readMore() {
        const url =
          this.getLocale === 'en'
            ? 'https://support.optimonk.com/hc/en-us/articles/12685994332562'
            : 'https://support.optimonk.hu/hc/hu/articles/12744779062162 ';
        window.open(url, '_blank');
      },
      watchVideo() {
        window.open('https://www.youtube.com/watch?v=z_60PYzxcAQ&ab_channel=OptiMonk', '_blank');
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .goals-description
    .example-goals

      @media screen and (max-width: 1399px)
        width: 100%

      .brand-select-card
        height: 60px !important
        min-width: 260px
        cursor: default

        &:hover
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07)

        &-title
          font-size: 14px
          color: $om-gray-700
</style>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .description-container
    .text-section,
    .example-section
      flex: 0 0 50%
      max-width: 50%
      z-index: 2

    @media screen and (max-width: 1399px)
        flex-direction: column

        .text-section,
        .example-section
          flex: 0 0 100%
          max-width: 100%

  .example-goals
    .conversion-number
      color: $om-orange-500
      font-weight: bold

  .goals-btns
    margin-top: 1rem
    margin-left: -0.5625rem


  .goals-description
    background: #FFF7F2
    padding: 2rem 2rem 1.5rem 2rem
    border-radius: 8px
    position: relative
    overflow: hidden

    .title
      color: $om-orange-500

    &-left-shape
      position: absolute
      left: 0
      top: 0
      height: 280px
    &-right-shape
      position: absolute
      right: 0
      bottom: 0

    @media (max-width: 1200px)
      &-left-shape,
      &-right-shape
        display: none

    .goals-desc
      color: $om-gray-700
      font-size: 14px
      line-height: 20px
</style>
