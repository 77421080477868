import { render, staticRenderFns } from "./DescriptionModule.vue?vue&type=template&id=97188908&scoped=true&lang=pug&"
import script from "./DescriptionModule.vue?vue&type=script&lang=js&"
export * from "./DescriptionModule.vue?vue&type=script&lang=js&"
import style0 from "./DescriptionModule.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./DescriptionModule.vue?vue&type=style&index=1&id=97188908&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97188908",
  null
  
)

export default component.exports